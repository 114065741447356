//var paytabs = require('paytabs');

var createPayPage = new Object();
function helloMethod(){
    src="https://paytabs.com/express/v4/paytabs-express-checkout.js"
    id="paytabs-express-checkout"
    data-secret-key;"FSy0JLNVgOHefc5JQ1QtyZZdx9nHqJ36BB6lmTgDIYRXaWH1XxuwwuJ4E7M4QParrgNOa4rT2jQdfxed5IJCdtGRAsVqi6SJmGyx"
    data-ui-type;"button"
    data-merchant-id;"10020153"
    data-url-redirect;"http://localhost:4300/#/consumer/checkout/MyOrdersTemplate"
    data-amount;"10.0"
    data-currency;"AED"
    data-title;"Bilal Sohail"
    data-product-names;"items from Akshaak"
    data-order-id;"2500"
    data-ui-show-header;"true"
    data-customer-phone-number;"5486253"
    data-customer-email-address;"bilal@evento.ae"
    data-customer-country-code;"971"
    data-ui-show-billing-address;"false"
    data-billing-full-address;"test test test"
    data-billing-city;"test"
    data-billing-state;"test"
    data-billing-country;"BHR"
    data-billing-postal-code;"123"
//   createPayPage.merchant_email= 'raza@evento.ae';
// createPayPage.secret_key = "FSy0JLNVgOHefc5JQ1QtyZZdx9nHqJ36BB6lmTgDIYRXaWH1XxuwwuJ4E7M4QParrgNOa4rT2jQdfxed5IJCdtGRAsVqi6SJmGyx";
// createPayPage.site_url = "https://www.geniusescode.net";
// createPayPage.return_url = "http://www.myreturn.com/";
// createPayPage.title = "some title";
// createPayPage.cc_first_name =  "ALGHABBAn";
// createPayPage.cc_last_name= "ALGHABBAN";
// createPayPage.cc_phone_number = "996";
// createPayPage.phone_number = "50000000";
// createPayPage.email = "clinet@outlook.com";
// createPayPage.products_per_title = "some title";
// createPayPage.unit_price = 150;
// createPayPage.quantity=  "1";
// createPayPage.other_charges = 0;
// createPayPage.amount=  150;
// createPayPage.discount = 0;
// createPayPage.currency = "SAR";
// createPayPage.reference_no =  "21873109128";
// createPayPage.ip_customer = "192.168.1.1";
// createPayPage.ip_merchant= "192.168.1.1";
// createPayPage.billing_address = "Flat 11 Building 222 Block 333 Road 444 Riydh";
// createPayPage.state =  "Riydh";
// createPayPage.city = "Riydh";
// createPayPage.postal_code=  "12345";
// createPayPage.country =  "SAU";
// createPayPage.shipping_first_name =  "Clinicarea";
// createPayPage.shipping_last_name = "app";
// createPayPage.address_shipping = "Flat abc road 123";
// createPayPage.city_shipping = "Riydh";
// createPayPage.state_shipping=  "Riydh";
// createPayPage.postal_code_shipping = "403129";
// createPayPage.country_shipping =  "SAU";
// createPayPage.msg_lang = "ar";
// createPayPage.cms_with_version = "1.0.0";

paytabs.CreatePayPage(createPayPage, function(response){
  console.log(response);
});
}